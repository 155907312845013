@import "@/assets/scss/var.scss";

































.notfound {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100vh;
    .main-inner {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .icon {
        width: 400px;
        height: 400px;
        margin-top: -50%;
    }
    .msg {
        margin: ($offset-v-lg * 2) 0 $offset-v-lg;
    }
    .desc a {
        color: #7bb2f9;
    }
}
